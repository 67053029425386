<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="service"
      :metadata="metadata"
      :translatePrefix="translatePrefix"
      @gridReady="onGridReady"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, useModuleOpen, helpers } from '@cargill/shared'
import createService from '../api/notificationService'
import { CrudAction } from '@brain/core'

const { getTranslatePrefix } = useModuleOpen()

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      service: null
    }
  },
  computed: {
    translatePrefix() {
      return getTranslatePrefix()
    }
  },
  created() {
    this.service = createService()
    window.notificationService = this.service
    this.service.getMeta().then((meta) => {
      this.metadata = meta
      const removedPermissions = new Set([CrudAction.UPDATE])
      meta.permissions = meta.permissions.filter(
        (p) => !removedPermissions.has(p)
      )
      meta.actions = [
        {
          title: this.$t('application.actions.markAsRead'),
          className: 'fas fa-check',
          enable: ({ node }) => {
            return node.data.readDate ? false : true
          },
          callback: ({ node }) => {
            this.markAsRead(node.data)
          }
        }
      ]
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey: 'application.pages.notification.markAllAsRead',
          onClick: this.onMarkAllAsRead,
          iconColor: '#959DB5',
          faIcon: 'fas fa-check-double'
        }
      ]
    })
  },
  mounted() {
    this.$root.$on('onNotificationUpdate', () => {
      this.reloadData()
    })
  },
  methods: {
    reloadData() {
      this.getCrudGridComponent().reloadData()
    },
    getCrudGridComponent() {
      return helpers.findComponent(this, 'CgCrudGrid')
    },
    getGridComponent() {
      return helpers.findComponent(this, 'CgGrid')
    },
    async markAsRead(notification) {
      await this.service.markAsRead([notification.id])
      this.$root.$emit('onNotificationMarkAsReadInGrid')
      this.notifySucess()
      this.reloadData()
    },
    async onMarkAllAsRead() {
      const vm = this
      await this.$brain.confirm(
        {
          title: this.$t('core.crud.attention'),
          subtitle: this.$tc(
            'application.pages.notification.markAllAsReadMessage'
          )
        },
        async () => {
          try {
            let success = await vm.markAllAsRead()
            if (success) {
              vm.$root.$emit('onNotificationMarkAsReadInGrid')
              vm.reloadData()
            }
          } catch (err) {
            console.error('Failed to mark all as read', err)
            this.notify.error({
              title: this.$tc('core.misc.error')
            })
          }
        }
      )
    },
    async markAllAsRead() {
      const resp = await this.service.markAllAsRead()
      if (this.hasErrors(resp)) {
        this.notify.error({ title: this.$tc('core.misc.error') })
        return false
      } else {
        this.notify.success({ title: this.$t('application.misc.success') })
        return true
      }
    },
    hasErrors(response) {
      return response.error
    },
    notifySucess() {
      this.notify.success({ title: this.$t('application.misc.success') })
    },
    onGridReady() {
      const gridOptions = this.getGridComponent().aggridOptions
      gridOptions.api.setSortModel([
        { colId: 'readDate', sort: 'desc' },
        { colId: 'notificationDate', sort: 'desc' }
      ])
      gridOptions.api.onFilterChanged()
    }
  }
}
</script>
